<template>
  <main>
    <!-- Hero section -->
    <section
      class="hero d-flex flex-column align-items-center justify-content-center text-center"
    >
      <div
        class="top-bar d-flex justify-content-between align-items-center mb-2"
      >
        <a
          href="#"
          class="btn home-button mb-2 d-flex align-items-center"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          v-if="isLogin == '' || isLogin == undefined"
        >
          <div class="text-content me-2" style="color: slategray">
            <p class="profile-title mb-0 fw-bold text-end" style="color: white">
              Guest
            </p>
            <p
              class="profile-description-text mb-0 fw-bold"
              style="color: lightblue"
            >
              Klik Untuk Login
            </p>
          </div>
          <img
            src="/img/64.png"
            alt="Player Icon"
            class="recent-payment-icon1"
          />
        </a>
        <a
          href="#"
          class="btn home-button mb-2 d-flex align-items-center"
          data-bs-toggle="modal"
          data-bs-target="#logoutModal"
          v-else
        >
          <div class="text-content me-2" style="color: slategray">
            <p
              class="profile-title mb-0 fw-bold text-end"
              style="color: white"
              v-if="isBed == 'false'"
            >
              {{ isLogin }}
            </p>
            <p
              class="profile-title mb-0 fw-bold text-end"
              style="color: white"
              v-else
            >
              .{{ isLogin }}
            </p>
          </div>
          <img
            :src="
              isLogin
                ? `https://minotar.net/avatar/${isLogin}.png`
                : '/img/64.png'
            "
            alt="Player Icon"
            class="recent-payment-icon1"
          />
        </a>
        <a
          href="#"
          class="btn btn-secondary login-button mb-2 d-flex align-items-center"
        >
          <i class="bi bi-reply-fill me-2"></i>
          <span class="d-none d-md-inline fw-bold">Home</span>
        </a>
      </div>
      <img
        src="/img/logo.png"
        alt="JacuzziCraft Store Logo"
        class="logo img-fluid"
      />
    </section>

    <!-- Store Info section -->
    <section class="store-info">
      <div class="container-fluid">
        <div class="row">
          <!-- Customer Info -->
          <div class="col-md-3">
            <div class="customer-info">
              <!-- Coins -->
              <div class="coins bg-dark text-light p-3 rounded mb-3">
                <h3>Coins</h3>
                <strong>
                  Selamat datang di Store
                  <span class="text-white">Jacuzzi Craft</span>.<br /><br />
                  Silahkan pilih JCash yang ingin kamu beli. jika ada kendala.
                </strong>
              </div>
              <!-- Recent Payments -->
              <div class="recent-payments bg-dark text-light p-3 rounded mb-3">
                <h3>RECENT PAYMENTS</h3>
                <div class="d-flex flex-wrap">
                  <div v-for="(item, index) in recentUsers.data" :key="index">
                    <div v-if="tooltips[index]" class="tooltip">
                      <div class="tooltip-pad">
                        <h3>{{ extractNickname(item.order_id) }}</h3>
                        <p>{{ formatNumber(item.product.value) }}</p>
                        <h3>JCash</h3>
                      </div>
                    </div>
                    <img
                      @mouseover="showTooltip(index)"
                      @mouseleave="hideTooltip(index)"
                      :src="`https://minotar.net/avatar/${item.unique_id}`"
                      alt="Player Icon"
                      class="recent-payment-icon me-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Store Info Wrap -->
          <div class="col-md-9">
            <!-- Store Terms -->
            <div class="store-terms bg-dark text-light p-3 rounded mb-3">
              <h2>Ketentuan Store</h2>
              <p style="margin-left: 0">
                <strong>
                  JCash bersifat permanen pada akun yang telah diberikan.<br /><br />
                  Tidak dapat mengajukan pengembalian dana untuk transaksi yang
                  telah berhasil.<br /><br />
                  Mengganti gamertag atau nickname Minecraft, semua data akan
                  direset menjadi baru, termasuk JCash yang sudah dibeli.
                </strong>
              </p>
            </div>

            <!-- Featured Products -->
            <div class="featured-products">
              <div class="row">
                <!-- Product Item -->
                <div
                  v-for="item in products.data"
                  :key="item.id"
                  class="col-lg-3 col-md-4 col-sm-6 mb-4"
                >
                  <div
                    class="product bg-dark text-light p-3 rounded d-flex flex-column align-items-center"
                  >
                    <img
                      :src="
                        item.value == 1000
                          ? `/img/1000.webp`
                          : item.value == 2500
                          ? `/img/2500.webp`
                          : item.value == 5000
                          ? `/img/5000.webp`
                          : item.value == 10000
                          ? `/img/10000.webp`
                          : item.value == 20000
                          ? `/img/20000.webp`
                          : item.value == 30000
                          ? `/img/30000.webp`
                          : item.value == 50000
                          ? `/img/50000.webp`
                          : item.value == 100000
                          ? `/img/100000.webp`
                          : item.value == 300000
                          ? `/img/300000.webp`
                          : `/img/300000.webp`
                      "
                      alt="Product 1"
                      class="img-fluid product-img mb-2"
                    />
                    <p class="product-title">
                      {{ formatNumber(item.value) }} JCash
                    </p>
                    <div v-if="item.promotion != null" class="discount-price">
                      <p class="strikethrough">
                        Rp.{{ formatNumber(item.base_price) }}
                      </p>
                      <p class="product-price">
                        Rp.{{ formatNumber(item.final_price) }}
                      </p>
                    </div>
                    <div v-else>
                      <p class="product-price">
                        Rp.{{ formatNumber(item.base_price) }}
                      </p>
                    </div>
                    <div v-if="isLogin == '' || isLogin == undifined">
                      <a
                        class="btn btn-warning btn-sm"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                      >
                        <i class="mdi mdi-plus-thick"></i>
                        Tambah Keranjang
                      </a>
                    </div>
                    <div v-else>
                      <a
                        class="btn btn-warning btn-sm"
                        @click="
                          redirectToCheckoutPage(
                            item.final_price,
                            item.value,
                            item.id
                          )
                        "
                      >
                        <i class="mdi mdi-plus-thick"></i>
                        Tambah Keranjang
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="text-center p-3 bg-dark text-light">
      Copyright ©️ Jacuzzi Craft 2024. All Rights Reserved. We are not
      affiliated with Mojang AB.
    </footer>

    <!-- Modal -->
    <div
      class="modal fade"
      id="myModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <h5>Tuliskan gamertagmu untuk melanjutkannya</h5>
            <p>Tulis usernamemu dibawah ini!</p>
            <div class="input-group mb-3 username-input">
              <span class="input-group-text bg-secondary"
                ><img :src="playerIcon" alt="Icon" />
                <input
                  type="text"
                  class="form-control"
                  placeholder="Masukkan username"
                  v-model="username"
                  @keypress="handleUsernameInput"
                  @input="checkName(username)"
                />
              </span>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-success buy-button w-100"
              @click="existUser"
              :disabled="userName == '' ? true : false"
            >
              Beli Sekarang
            </button>
            <div class="footer-container">
              <p>Bermain di Bedrock?</p>
              <!-- <ToggleSwitch class="toggle-switch" /> -->
              <div class="toggle-switch">
                <input
                  type="checkbox"
                  id="toggleSwitch"
                  v-model="isChecked"
                  @change="handleStatus"
                />
                <label for="toggleSwitch">
                  <span class="slider">
                    <!-- Teks "Yes" dan "No" akan ditangani dalam slider:before -->
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Logout -->
    <div
      class="modal fade"
      id="logoutModal"
      tabindex="-1"
      aria-labelledby="logoutModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-dark text-light">
          <div class="modal-header">
            <h5 class="modal-title" id="logoutModalLabel">
              Logout Confirmation
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">Apakah kamu yakin ingin keluar?</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-danger" @click="confirmLogout">
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import Cookies from "js-cookie";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";

export default {
  data() {
    return {
      tooltips: [],
      products: [],
      recentUsers: [],
      checkUsername: {},
      userName: "",
      isChecked: false,
      isLogin: "",
      isBed: null,
      pageTitle: "Jacuzzi Webstore | Home",
    };
  },
  mounted() {
    document.title = this.pageTitle;
    this.listProduct();
    this.listRecent();
    this.isLogin = Cookies.get("user-profile");
    this.isBed = Cookies.get("bedrock");
    this.playerIcon = "/img/64.png";
    Cookies.remove("Total");
    Cookies.remove("jcash");
    Cookies.remove("id-produk");
  },
  watch: {
    pageTitle(newTitle) {
      document.title = newTitle;
    },
    userName(newUsername) {
      const defaultImageUrl = "/img/64.png";
      const imageUrl = `https://minotar.net/avatar/${newUsername}.png`;
      this.checkImageAvailability(imageUrl, defaultImageUrl);
    },
  },
  methods: {
    extractNickname(orderId) {
      return orderId.split("-")[0]; // Mengambil bagian pertama dari order_id yang dipisahkan dengan "-"
    },
    async checkImageAvailability(url, defaultUrl) {
      const img = new Image();
      img.onload = () => {
        // Image loaded successfully
        this.playerIcon = url;
      };
      img.onerror = () => {
        // Image failed to load, keep the fallback image
        this.playerIcon = defaultUrl;
        console.log("Image not available, using fallback.");
      };
      img.src = url;
    },
    showTooltip(index) {
      this.tooltips[index] = true;
    },
    hideTooltip(index) {
      this.tooltips[index] = false;
    },
    redirectToCheckoutPage(amnt, jcsh, id) {
      Cookies.set("Total", amnt, {
        expires: 1 / 24,
      });
      Cookies.set("jcash", jcsh, {
        expires: 1 / 24,
      });
      Cookies.set("id-produk", id, {
        expires: 1 / 24,
      });
      this.$router.push({
        name: "CheckoutPage",
      });
    },
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    existUser() {
      this.checkName(this.userName);
      setTimeout(() => {
        if (this.checkUsername.response_code == 400) {
          new Noty({
            type: "error",
            layout: "top",
            text: "Username Tidak Ditemukan",
            timeout: 3000,
          }).show();
        } else {
          Cookies.set("user-profile", this.checkUsername.data.nick_name, {
            expires: 1 / 24,
          });
          Cookies.set("bedrock", this.isChecked, {
            expires: 1 / 24,
          });
          new Noty({
            type: "success",
            layout: "top",
            text: "Username Ditemukan",
            timeout: 3000,
          }).show();
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      }, 1000);
    },
    confirmLogout() {
      Cookies.remove("user-profile");
      Cookies.remove("bedrock");
      Cookies.remove("Total");
      Cookies.remove("jcash");
      Cookies.remove("auth-token");
      this.$router.push("/");
      setTimeout(() => {
        location.reload();
      }, 500);
    },
    handleStatus(event) {
      this.status = event.target.checked ? true : false;
      Cookies.set("bedrock", this.isChecked, {
        expires: 1 / 24,
      });
    },
    listProduct() {
      axios
        .get(`${process.env.VUE_APP_CONTENT}/v1/midtrans/products`)
        .then((response) => {
          this.products = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listRecent() {
      axios
        .get(
          `${process.env.VUE_APP_CONTENT}/v1/midtrans/recent-purchase-history?limit=5`
        )
        .then((response) => {
          this.recentUsers = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkName(name) {
      axios
        .get(
          `${
            process.env.VUE_APP_CONTENT
          }/v1/midtrans/check-nickname?nick_name=${
            this.isChecked ? `.${name}` : name
          }`
        )
        .then((response) => {
          this.checkUsername = response.data;
          this.userName = name;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleUsernameInput(event) {
      if (event.key === ".") {
        event.preventDefault();
      }
    },
  },
};
</script>

<template>
  <div>
    <h1>Menu History Page</h1>
    <p>Coming Soon</p>
  </div>
</template>

<script>
export default {
  name: "HistoryPage",
};
</script>

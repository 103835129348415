import CheckoutPage from "@/pages/CheckoutPage.vue";
import HomePage from "@/pages/HomePage.vue";
import FailedPage from "@/pages/payment/FailedPage.vue";
import SuccessPage from "@/pages/payment/SuccessPage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import PrivatePage from "@/pages/PrivatePage.vue";
import SidebarLayout from "@/components/SidebarLayout.vue";
import PromoPage from "@/pages/dashboard/promo/PromoPage.vue";
import HistoryPage from "@/pages/dashboard/history/HistoryPage.vue";
import Cookies from "js-cookie";
import { createRouter, createWebHistory } from "vue-router";

function isLoggedIn() {
  return !!Cookies.get("user-profile");
}
function isTotal() {
  return !!Cookies.get("Total");
}
function isCheckout() {
  return !!Cookies.get("jcash");
}
function hasAuthToken() {
  return !!Cookies.get("auth-token");
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/CheckoutPage",
    name: "CheckoutPage",
    component: CheckoutPage,
    beforeEnter: (to, from, next) => {
      if (isLoggedIn() && isTotal() && isCheckout()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/success",
    name: "SuccessPage",
    component: SuccessPage,
  },
  {
    path: "/failed",
    name: "FailedPage",
    component: FailedPage,
  },
  {
    path: "/Dashboard",
    name: "DashboardPage",
    component: SidebarLayout,
    children: [
      {
        path: "/promo",
        name: "PromoPage",
        component: PromoPage,
      },
      {
        path: "/history",
        name: "HistoryPage",
        component: HistoryPage,
      },
    ],
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/private",
    name: "PrivatePage",
    component: PrivatePage,
    beforeEnter: (to, from, next) => {
      if (hasAuthToken()) {
        next();
      } else {
        next("/"); // Redirect to Home if no token
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(""),
  routes,
});

export default router;

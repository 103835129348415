<template>
  <div class="layout">
    <aside class="sidebar">
      <div class="side-header">
        <img src="/img/header-logo.png" alt="Logo Icon" class="header-logo" />
      </div>
      <div class="hover-click">
        <div @click="toPromo" class="menu-side">
          <span> Promo </span>
        </div>
        <div @click="toHistory" class="menu-side">
          <span> History </span>
        </div>
      </div>
    </aside>
    <main class="content">
      <router-view />
    </main>
  </div>
</template>

<script>
export default {
  name: "SidebarLayout",
  methods: {
    toPromo() {
      this.$router.push({
        name: "PromoPage",
      });
    },
    toHistory() {
      this.$router.push({
        name: "HistoryPage",
      });
    },
  },
};
</script>

<style scoped>
.layout {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 20px;
}

.content {
  flex: 1;
  padding: 20px;
}

.header-logo {
  font-family: monospace;
  width: 64px;
}

h2 {
  font-family: monospace;
  font-size: 24px;
  font-weight: 700;
  margin: 0px;
}

.side-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  flex-direction: column;
}

div.hover-click :hover {
  background-color: #ffc107;
  cursor: pointer;
  font-size: 28px;
  transition: all 0.5s;
}

.menu-side {
  padding: 8px;
  font-family: monospace;
  font-size: 16px;
  font-weight: 700;
}
</style>

<template>
  <div class="card-main">
    <img src="/img/header-logo.png" alt="Logo Icon" class="header-logo" />
    <h1>Login JacuzziCraft</h1>

    <form @submit.prevent="login" class="card-form">
      <div class="field-input">
        <label for="username">Username</label>
        <input type="text" v-model="username" id="username" required />
      </div>

      <div class="field-input" style="margin-bottom: 24px">
        <label for="password">Password</label>
        <input type="password" v-model="password" id="password" required />
      </div>
      <button
        style="background-color: #ffc107; color: black; width: 320px"
        type="submit"
      >
        Login
      </button>

      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>
// import Cookies from "js-cookie";
import axios from "axios";
// import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";

export default {
  name: "LoginPage",
  data() {
    return {
      checkAuth: {},
      username: "",
      password: "",
      errorMessage: "",
    };
  },
  methods: {
    login() {
      //   this.authCheck(this.userName);
      //   setTimeout(() => {
      //     if (this.checkUsername.response_code == 400) {
      //       new Noty({
      //         type: "error",
      //         layout: "top",
      //         text: "Username Tidak Ditemukan",
      //         timeout: 3000,
      //       }).show();
      //     } else {
      //       Cookies.set("user-profile", this.checkUsername.data.nick_name, {
      //         expires: 1 / 24,
      //       });
      //       Cookies.set("bedrock", this.isChecked, {
      //         expires: 1 / 24,
      //       });
      //       new Noty({
      //         type: "success",
      //         layout: "top",
      //         text: "Username Ditemukan",
      //         timeout: 3000,
      //       }).show();
      //       setTimeout(() => {
      //         location.reload();
      //       }, 1000);
      //     }
      //   }, 1000);
      this.$router.push({
        name: "HistoryPage",
      });
    },
    authCheck(log) {
      axios
        .get(
          `${
            process.env.VUE_APP_CONTENT
          }/v1/midtrans/check-nickname?nick_name=${
            this.isChecked ? `.${log}` : log
          }`
        )
        .then((response) => {
          this.checkAuth = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.error {
  font-family: monospace;
  color: red;
}

.header-logo {
  font-family: monospace;
  width: 125px;
}
.card-main {
  font-family: monospace;
  padding: 180px;
  padding-top: 90px;
}

.field-input {
  font-family: monospace;
  display: flex;
  flex-direction: column;
  width: 320px;
}

.card-form {
  font-family: monospace;
  display: flex;
  align-items: center;
}
h1 {
  font-family: monospace;
  font-size: 24px;
  font-weight: 700;
}
</style>

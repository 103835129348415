<template>
  <div>
    <h1><b>Promo</b></h1>
    <div>
      <table
        class="uk-table uk-table-divider"
        aria-describedby="campaign-table"
      >
        <thead class="uk-background-muted sticky-header">
          <tr>
            <th
              v-for="(header, index) in headerTablePromo"
              :key="index"
              class="header-item"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <content-promo />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import contentPromo from "./ContentTablePromo";

export default {
  data: () => ({
    headerTablePromo: [
      "Name",
      "Code",
      "Disc",
      "Counter",
      "Usage Limit",
      "Validity",
      "Status",
      "Created At",
      "Updated At",
      "Action",
    ],
  }),
  components: {
    contentPromo,
  },
  name: "PromoPage",
};
</script>

<style scoped>
/* .uk-table {
  width: -webkit-fill-available;
}
.uk-table th {
  text-transform: capitalize;
  white-space: nowrap;
  color: #22252f;
  font-weight: 600;
  padding: 4px 6px;
  font-family: monospace;
  font-size: 14px;
  background-color: #ffc107;
}
.uk-background-muted {
  background-color: #f9f9fb;
}
.sticky-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
} */
h1 {
  max-width: 250px;
  min-width: 100px;
  font-family: monospace;
  font-weight: 700;
  border-bottom: 6px solid #ffc107;
  margin-bottom: 12px !important;
  display: flex;
}

table {
  border-collapse: collapse;
  width: 100%;
  table-layout: auto !important;
  word-wrap: break-word;
}

.header-item {
  padding: 20px 10px;
  font-size: 14px;
  background-color: #ffc107;
  text-transform: uppercase;
  font-family: monospace;
  white-space: nowrap;
}

.table-rows:nth-child(odd) {
  background-color: #ffc107;
}

.table-rows:nth-child(n):hover {
  background-color: #ffc107;
}
</style>

<template>
  <div>
    <h1>Private Page</h1>
    <p>This page is only accessible to users with a valid token.</p>
  </div>
  <div>
    <h1>Private Page</h1>
    <p>This page is only accessible to users with a valid token.</p>
  </div>
</template>

<script>
export default {
  name: "PrivatePage",
};
</script>

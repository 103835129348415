<template>
  <tr v-for="(content, index) in listPromo.data" :key="index">
    <td>{{ content.name }}</td>
    <td>{{ content.code }}</td>
    <td>{{ content.disc }}%</td>
    <td>{{ content.counter }}</td>
    <td>{{ content.usage_limit }}</td>
    <td>{{ formatDate(content.validity) }}</td>
    <td v-if="content.is_active"><span>Active</span></td>
    <td v-else><p>Inactive</p></td>
    <td>{{ formatDate(content.created_at) }}</td>
    <td>{{ formatDate(content.updated_at) }}</td>
    <td><button>Edit</button></td>
  </tr>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      listPromo: [],
    };
  },
  mounted() {
    this.listGetPromo();
  },
  methods: {
    listGetPromo() {
      axios
        .get(`${process.env.VUE_APP_CONTENT}/v1/midtrans/promos`)
        .then((response) => {
          this.listPromo = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      return d.toLocaleDateString("en-GB").replace(/\//g, "-");
    },
  },
};
</script>

<style scoped>
/* .uk-table td {
  white-space: nowrap;
  color: #22252f;
  font-size: 16px;
  font-family: monospace;
  font-weight: 400;
  vertical-align: middle;
  border-bottom: 1px solid;
} */
td {
  padding: 24px 12px;
  text-align: center;
  border-bottom: 1px solid rgb(224, 242, 237);
  font-family: monospace;
  font-weight: 400;
}

.uk-table span {
  font-family: monospace;
  font-weight: 600;
  padding: 5px 8px;
  border-radius: 5px;
  color: #2cc995;
  background-color: #eefbf7;
}

.uk-table p {
  font-family: monospace;
  font-weight: 600;
  padding: 5px 8px;
  border-radius: 5px;
  color: #e93535;
  background-color: #fdeded;
}

.uk-table td.action {
  background: #fff;
  width: 120px;
  padding: 15px 12px;
  box-shadow: -1px 0px 5px 1px rgba(166, 166, 166, 0.1);
  -webkit-box-shadow: -1px 0px 5px 1px rgba(166, 166, 166, 0.1);
  -moz-box-shadow: -1px 0px 5px 1px rgba(166, 166, 166, 0.1);
  display: flex;
  justify-content: space-around;
}
.disabled {
  cursor: no-drop;
}
</style>
